$gray: #667085;
$primary: #0788F5;
$error: #F04438;
$warning: #F79009;
$success: #17B26A;
$primary-color: #09090B;
$secondary-color: #71717A;

$sidebar-width: 258px;
$sidebar-bg: #F2F2F7;

$mobile-header-size: 60px;
$mobile-footer-size: 60px;

html{scroll-behavior:smooth}
* {scrollbar-width:none}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: Eventpop;
  src: url(../src/fonts/Eventpop/eventpop-bold.ttf);
  font-weight: 700;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family: Eventpop;
  src: url(../src/fonts/Eventpop/eventpop-medium.ttf);
  font-weight: 500;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family:'DB Helvethaicamon X';
  src: url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X.woff2') format('woff2'),
    url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X.woff') format('woff');
  font-weight:400;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family:'DB Helvethaicamon X';
  src: url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Med.woff') format('woff'),
    url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Med.woff') format('woff');
  font-weight:500;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family:'DB Helvethaicamon X';
  src: url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Med.woff') format('woff'),
    url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Med.woff') format('woff');
  font-weight:600;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family:'DB Helvethaicamon X';
  src: url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Bd.woff2') format('woff2'),
    url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Bd.woff') format('woff');
  font-weight:700;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family:'DB Helvethaicamon X';
  src: url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Blk.woff2') format('woff2'),
    url('../src/fonts/DB Helvethaicamon X/DB HelvethaicaMon X Blk.woff') format('woff');
  font-weight:800;
  unicode-range: 'U+0E00–U+0E7F';
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Cal Sans';
  src: url('./fonts/Cal sans/CalSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:'Cooper Black';
  src: url('./fonts/Cooper Black/CooperFiveOpti-Black.otf')
}

@font-face {
  font-family:'Cooper Black';
  src: url('./fonts/Cooper Black/CooperFiveOpti-Black.otf')
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYHEAVYITALIC.OTF') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYLIGHTITALIC.OTF') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYTHINITALIC.OTF') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/SF Pro/SF-Pro-Display-Semibold.woff');
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./fonts/sf-pro/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF Pro Rounded/SF-Pro-Rounded-Light.woff2') format('woff2');
  font-weight:300;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF Pro Rounded/SF-Pro-Rounded-Regular.woff2') format('woff2');
  font-weight:400;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF Pro Rounded/SF-Pro-Rounded-Medium.woff2') format('woff2');
  font-weight:500;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF Pro Rounded/SF-Pro-Rounded-Semibold.woff2') format('woff2');
  font-weight:600;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./fonts/SF Pro Rounded/SF-Pro-Rounded-Bold.woff2') format('woff2');
  font-weight:700;
}

@font-face {
  font-family: 'Recoleta';
  src: url('./fonts/Recoleta/Recoleta-RegularDEMO.woff') format('woff');
}

body { 
  font-family: 'SF Pro Rounded', 'SF Pro Display', 'DB Helvethaicamon X', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  pointer-events:auto !important;
}

.bg-gradient{background: linear-gradient(50deg, #FFF 78.99%, #D1B5FF 271.14%)}
.inter{font-family:'Inter', sans-serif}
.eventpop{font-family:'Eventpop', sans-serif}
.cal-sans{font-family:'Cal Sans', 'SF Pro Display', sans-serif}
.cooper-black{font-family:'Cooper Black'}
.poppins{font-family:'Poppins', sans-serif}
.helvet{font-family:'DB Helvethaicamon X', sans-serif}
.sf-pro{font-family:'SF Pro Display', 'DB Helvethaicamon X', sans-serif}
.sf-pro-rounded{font-family:'SF Pro Rounded', sans-serif}
.recoleta{font-family:'Recoleta', sans-serif}

.see-more {
  opacity:0;
  transition:opacity 100ms;
}

.login-line-btn {
  border-radius:8px;
  background:linear-gradient(152deg, #00B945 0%, #00D14E 100%);
  box-shadow:0 4px 10px 0 rgba(17, 192, 82, 0.05); 
  display:inline-flex;
  width:325px;
  color:white;
  padding:4px;
  justify-content:center;
  align-items:center;
  gap:8px;
  font-size:24px;
  font-weight:700
}

.form-input {
  border-radius:6px;
  border:1px solid #F4F4F5;
  background:#F4F4F5;
  font-size:14px;
  padding:8px 12px;
  &.select-input {
    appearance:none;
    background-image:url("../src/img/chevron-input.svg");
    background-repeat:no-repeat;
    background-position:right 12px top 50%;
  }
}

.main-heading {
  font-size:47px;
  font-style:normal;
  font-weight:700;
  line-height:32px;
  color:$primary-color;
}

.domain-heading {
  font-size:20px;
  font-weight:700;
  letter-spacing:-0.5px;
  line-height:28px;
  color:$primary-color;
}

.settings-heading {
  font-size:16px;
  line-height:16px;
  font-weight:600;
  margin-bottom:4px;
  letter-spacing:-0.4px;
  color:$primary-color
}

.main-desc {
  font-size:14px;
  line-height:20px;
  color:$secondary-color
}

.secondary-desc {
  font-size:16px;
  line-height:24px;
  color:$secondary-color
}

.subheading {
  font-size:14px;
  line-height:20px;
  color:$primary-color
}

.text-desc {
  font-size:12px;
  line-height:16px;
  color:$secondary-color
}

.menu-card,.menu-card-app-store {
  cursor:pointer;
  overflow:hidden;
  border-radius:10px;
  min-width:164px;
  min-height:174px;
  width:164px;
  height:174px;
  font-size:14px;
  font-weight:500;
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.menu-card {
  &:nth-child(5),&:nth-child(6){
    padding-top:20px;
  }
  &:nth-child(4){
    padding-top:16px;
    svg {
      width:240px;
    }
  }
}

.menu-card-app-store {
  &:nth-child(1),&:nth-child(2),&:nth-child(5),&:nth-child(6){
    padding-top:16px;
  };
  &:nth-child(3){
    svg {
      margin-right:8px;
    }
  };
  &:nth-child(6){
    svg {
      margin-left:16px;
    }
  }
}

#sidebar {
  width:$sidebar-width;
  position:fixed;
  overflow:auto;
  top:0;
  left:0;
  height:100%;
  padding:26px 17px 6px 22px;
  display:flex;
  flex-direction:column;
  gap:24px;
  &.hidden {
    visibility:hidden;
  }
}

.outer-page {
  margin:5px;
  overflow:auto;
  border-radius:22px;
  // box-shadow: 0 0 16.4px 0 #0000000F, 0 0 0 1000px $sidebar-bg;
  box-shadow:0px 0px 0px 1px rgba(64,87,109,.04),0px 6px 20px -4px rgba(64,87,109,.3), 0 0 0 1000px $sidebar-bg;
  transition:margin-left 300ms;
  position:relative;
  height:calc(100dvh - 10px);
  box-sizing:border-box;
  top:0;
  &.active {
    margin-left:$sidebar-width;
  }
}

#iframe-page {
  position:fixed;
  top:5px;
  right:5px;
  border-radius:22px;
  width:calc(100% - ($sidebar-width + 5px));
  height:calc(100dvh - 10px);
  overflow:hidden;
  z-index:11;
  background-color:white;
  transition:all 300ms;
  &.inactive {
    width:calc(100% - 10px);
    .iframe-content {
      width:calc(100% - 10px);
      &.enter-fullscreen {
        width:100%;
      }
    }
  }
}

.iframe-content {
  position:fixed;
  width:calc(100% - ($sidebar-width + 5px));
  height:calc(100dvh - (10px + 54px));
  right:5px;
  top:59px;
  border-radius:0 0 22px 22px;
  z-index:9;
  transition:width 300ms;
  &.enter-fullscreen {
    position:fixed;
    width:100%;
    height:100%;
    border-radius:0;
    top:0;
    right:0;
    transition:all 500ms;
  }
}

.page-section {
  padding:0 0 80px;
  box-sizing:content-box;
  margin:auto;
  background-color:white;
}

.page-container {
  max-width:1100px;
  margin:auto;
  padding:0 20px;
  box-sizing:content-box;
}

.app-detail-icon svg {
  width:72px;
  height:72px;
}

/* Sidebar style */
.nav-btns {
  min-width:36px;
	min-height:36px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
  box-sizing:border-box;
	border-radius:6px;
  background-color:#F4F4F5;
	outline:0px double #CECECE;
	border:1px solid #E4E4E7;
  color:#6B7280;
  &.hide {
    display:none;
  }
  &.show {
    display:inherit;
  }
  &.add {background-color:white}

	&.add-ons {
    border:none;
    box-sizing:border-box;
    &:hover {
      outline:2px double #CECECE
    }
  }
}

/* End of sidebar style */

.topbar {
  box-sizing:border-box;
  position:sticky;
  top:0;
  right:0;
  padding:10px 14px 15px;
  display:grid;
  grid-template-columns:repeat(6, 1fr);
  align-items:center;
  z-index:10;
  width:100%;
  background-color:white;
  max-height:76px;
}

.step-register {
  display:flex;
  flex-direction:column;
  border-radius:999px;
  height:4px;
  width:60px;
  background-color:#e5e7eb;

  .step-register-inner {
    height:4px;
    border-radius:999px;
    background-color:#0F172A;
    width:0%;
    transition:width 200ms;
    &.complete {
      width:100%;
      transition:width 200ms
    }
  }
}

.primary-btn {
  display:inline-flex;
  border:1px solid transparent;
  align-items:center;
  border-radius:8px;
  font-size:14px;
  line-height:24px;
  color:white;
  font-weight:600;
  background-color:$primary;
  padding:7px 10px;
  &.disabled {
    background-color:#b4ddff;
    cursor:not-allowed;
  }
  &.error {
    background-color:$error;
  }
}

.checkbox-card {
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius:8px;
  overflow:hidden;
  cursor:pointer;
  height:100%;
}

.checkbox-card-input {
  opacity:0;
  position:absolute;
}

.checkbox-card-input:checked ~ .checkbox-card {
  border-color:$primary;
}

.moving-line {
  height:5px;
  width:100%;
  background-color:red;
  position:absolute;
  z-index:99;
  top:0;
  left:0;
  animation:moving-line 2s linear infinite;
  background:linear-gradient(to right, #006AFF 0%, #0094FF 30%, #dad3d336 55%, #C293FF 80%, #006AFF 100%);
  background-size:200% 100%;
  background-position:100% 0;
}

.loading-icon{
  width:20px; /* Customise the width */
  height:20px; /* Customise the height */
  border-radius:50%;
  background:linear-gradient(0deg, $primary, #F2F2F2);
  margin:auto;
}

.inner-icon{
  border-radius:50%;
  margin:auto;
  display:block;
  width:16px; /* Customise the width */
  height:16px; /* Customise the height */
  background:white;
  transform:translate(0,2px) /* Just customise the Y axis */
}

.table-invoice-detail td {
  padding:8px 0;
}

.fade-in {
  animation:fadeIn 1.5s forwards;
  animation-delay:.3s;
  opacity:0;
}

.fade-in-validation {
  animation:fadeIn .2s
}

.fade-in-loading-workspace {
  animation:fadeIn .5s forwards;
  opacity:0;
}

.bounce-btn {
  animation:bounceBtn .4s forwards
}

.app-card {
  background: linear-gradient(180deg, rgba(122, 148, 255, 0) 0%, rgba(8, 24, 55, 0.17) 23.51%, rgba(0, 13, 32, 0.47) 50%);
  position:relative;
  margin-top:30%;
  box-shadow:none;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  height:auto;
  &:hover .see-more {
    opacity:1;
    transition:opacity 100ms;
  }
}

.feature-card {
  margin-top:50%;
  position:relative;
}

.video-card {
  background:linear-gradient(180deg, rgba(122, 148, 255, 0) 0%, rgba(8, 24, 55, 0.17) 23.51%, rgba(0, 13, 32, 0.47) 50%);
  position:absolute;
  bottom:0;
  width:100%;
  margin-top:213px;
}

.domain-input { padding:0 160px 0 84px }

@keyframes fadeIn {
  from {opacity:0}
  to {opacity:1}
}

@keyframes bounceBtn {
  0% { transform:scale(0.01) }
  50% { transform:scale(1.2) }
  100% { transform:scale(1) }
}

@keyframes moving-line {
  0% { background-position: 200% 0 }
  100% { background-position: 0 0 }
}

@media (max-width:1024px){
  .main-heading {
    font-size:16px;
  }
  #iframe-page {
    width:calc(100% - 10px);
  }
  .settings-heading {
    font-size:18px;
  }
  .sidebar-top {
    margin-top:-64px;
  }
  .menu-card, .menu-card svg {
    min-width:280px;
    width:280px;
  }
  .page-container { padding:0 }
  .feature-card {
    margin-top:35vw;
  }
  #sidebar {
    z-index:99;
    box-shadow: 0 0 16.4px 0 #0000000F;
    transform:translate3d(-20%, 0, 0);
    background-color:$sidebar-bg;
    opacity:0;
    transition:transform 300ms, opacity 300ms;
  }
  .outer-page.active #sidebar {
    transform:translate3d(0, 0, 0);
    opacity:1;
  }
  .outer-page, .outer-page.active {
    margin-left:5px;
    z-index:9;
  }
  .app-card {
    margin-top:25vw;
  }
  .iframe-content {
    width:calc(100% - 10px);
  }
}

.loading-icon-check {
  display: inline-block;
  vertical-align: top;
  opacity: 1;
  overflow: visible;

  &.loading-icon-check-success {
    .success-tick {
      fill: none;
      stroke-width: 1px;
      stroke: #20D84A;
      stroke-dasharray: 15px, 15px;
      stroke-dashoffset: -14px;
      animation: success-tick 450ms ease 600ms forwards;
      opacity: 0;
    }

    .success-circle-outline {
      fill: none;
      stroke-width: 1px;
      stroke: #20D84A;
      stroke-dasharray: 72px, 72px;
      stroke-dashoffset: 72px;
      animation: success-circle-outline 300ms ease-in-out forwards;
      opacity: 0;
    }

    .success-circle-fill {
      fill: #bbf7d0;
      stroke: none;
      opacity: 0;
      animation: success-circle-fill 300ms ease-out 300ms forwards;
    }
  }

  &.loading-icon-check-primary {
    .success-tick {
      fill: none;
      stroke-width: 1px;
      stroke: $primary;
      stroke-dasharray: 15px, 15px;
      stroke-dashoffset: -14px;
      animation: success-tick 450ms ease 600ms forwards;
      opacity: 0;
    }
  
    .success-circle-outline {
      fill: none;
      stroke-width: 1px;
      stroke: $primary;
      stroke-dasharray: 72px, 72px;
      stroke-dashoffset: 72px;
      animation: success-circle-outline 300ms ease-in-out forwards;
      opacity: 0;
    }
  
    .success-circle-fill {
      fill: white;
      stroke: none;
      opacity: 0;
      animation: success-circle-fill 300ms ease-out 300ms forwards;
    }
  }

  @keyframes success-tick {
    0% {
      stroke-dashoffset: 16px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 31px;
      opacity: 1
    }
  }

  @keyframes success-circle-outline {
    0% {
      stroke-dashoffset: 72px;
      opacity: 1
    }

    100% {
      stroke-dashoffset: 0px;
      opacity: 1
    }
  }

  @keyframes success-circle-fill {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@media (max-width:767px){
  .register-screen { width:calc(100% - 60px) }
  .domain-input { padding-right:0 }
}

@media (min-width:1024px){
  .payment-form {
    box-shadow:-20px 0 30px -4px rgba(0, 0, 0, 0.04);
  }
}

/* Hello page */

.new__bg {
  height:100dvh;
  width:100dvw;
  position:fixed;
  background: linear-gradient(97deg, #CCECE7, #CAD2F9 70.71%);
  display:flex;
  justify-content:center;
  z-index:99;
}
.hello__div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 600px;
  padding:0 20px;
  box-sizing:content-box;
}
.hello__svg {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 48px;
  stroke-dasharray: 5800px;
  stroke-dashoffset: 5800px;
  animation: anim__hello linear 3s forwards;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

@keyframes anim__hello {
  0% { stroke-dashoffset: 5800 }
  25% { stroke-dashoffset: 5800 }
  100% { stroke-dashoffset: 0 }
}

/* RADIO BUTTON */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1ch;
  flex-wrap: wrap;
  transition: all var(--transition);
}

:root {
  --selected: #007AFF;
  --transition: 0.875s;
  --bg: light-dark(white, black);
  --intentful: light-dark(color-mix(in lch, canvas, canvasText 15%), black);
  --ease: linear(0 0%,
    0.6832 7.89%,
    0.9171 11.07%,
    1.0251 12.99%,
    1.1058 14.9%,
    1.1619 16.86%,
    1.1945 18.91%,
    1.2024 20.02%,
    1.2043 21.18%,
    1.1907 23.72%,
    1.1598 26.27%,
    1.0604 32.59%,
    1.0172 35.84%,
    0.9839 39.49%,
    0.967 43.26%,
    0.9639 45.77%,
    0.9661 48.59%,
    0.9963 60.54%,
    1.0054 67.42%,
    1 100%
  );
}

.radio-button-check {
  --active: 0;
  --intent: 0;
  --accent: color-mix(in lch, canvasText, canvas 25%);
  --backdrop: light-dark(white, black);
  padding: 11px 17px;
  color: var(--accent);
  border-radius: 100px;
  border: 1px solid #E4E4E4;
  display: grid;
  grid-template-columns: auto calc(var(--active) * 20px);
  cursor: pointer;
  align-items: center;
  line-height: 1.1;
  transition: color, grid-template-columns;
  transition-duration: calc(var(--transition) * 0.5), var(--transition);
  transition-timing-function: var(--ease);
  position: relative;
  outline: 0px solid color-mix(in lch, canvas, canvasText 85%);
  outline-offset: 2px;
}

.radio-button-check::after {
  content: '';
  opacity: var(--active, 0);
  position: absolute;
  inset: -1px;
  border: 1px solid var(--selected);
  background:#007AFF26;
  border-radius: 100px;
  z-index: 2;
  pointer-events: none;
  transition: opacity var(--transition) var(--ease);
}

.radio-button-check span::selection {
  background: transparent;
}

.radio-button-check:has(:focus-visible),
.radio-button-check:hover {
  &:not(:has(:checked)) {
    --intent: 1;
  }
}

.radio-button-check:has(:focus-visible) {
  outline-width: 2px;
}

.radio-button-check:has(:checked) {
  --active: 1;
  color: var(--selected);
}

.radio-button-check:has(:checked) .title {
  color: var(--selected);
}

.radio-button-check .title {
  color: #687178;
  font-size:16px;
  letter-spacing:0.43px
}

.radio-button-check span {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.radio-button-check svg {
  opacity:var(--active, 0);
  justify-self:flex-end;
  position:absolute;
  width:18px;
  height:18px;
  left:0.75ch;
  transition-property:opacity;
  transition-duration:var(--transition);
  transition-timing-function:var(--ease);
}

.conic-gradient {
  position:absolute;
  background:conic-gradient(from 0deg at 50% 50%, #89C8F0 0deg, #88ABEF 48.63deg, #A586F0 90deg, #BF68EF 137.34deg, #DD6CAF 180deg, #EA8983 240deg, #F3B691 291.17deg, #ECD3B5 330.3deg, #89C8F0 360deg);
  width:237px;
  height:236px;
  clip-path:url(#gradi);
}