@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 240 5% 96%;
    --muted-foreground: 240 4% 46%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --hover-secondary:0 0% 89%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 240 25% 98%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%; 
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
 
@layer base {
  * {
    @apply border-border lg:tracking-[0];
    font-size-adjust: 0.49 !important;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@media only screen and (macOS) {
  * {
    font-size-adjust: 0.49 !important;
  }
}

html {
  scroll-behavior: smooth;
}

.main-container {
  @apply mx-auto px-[17px] box-content max-w-[1200px]
}

.regis-input {
  @apply text-base bg-transparent !ring-0 !ring-offset-0 !outline-0 h-11
}

.regis-btn {
  @apply max-w-[400px] w-full justify-center text-lg !bg-link shadow-none h-[53px] rounded-[15px]
}

.error {
  @apply text-error text-sm font-medium
}

.primary-heading {
  @apply text-3xl font-bold leading-10
}

.secondary-heading {
  @apply text-2xl font-bold leading-8
}

.regis-container {
  @apply m-auto text-base max-w-[400px] w-full
}

.btn-with-icon {
  @apply flex gap-2 items-center
}

.main-modal {
  @apply pt-12 bg-white max-h-[694px] max-w-[712px] w-[95%] h-[90%] rounded-xl z-5 z-99 p-0 overflow-hidden border-0 shadow-none flex flex-col outline-none !-tracking-[0.43px] lg:tracking-[0px]
}

.main-modal-header {
  @apply px-2 grid grid-cols-4 z-[99] fixed bg-white items-center min-h-[44px] top-0 left-0 w-full
}

.arrow-tab-btn {
  @apply absolute z-[9] outline-none bg-white shadow-md rounded-full h-8 w-8 flex items-center justify-center transition-all
}

.screen-dvh {
  @apply h-[100dvh]
}

.sidebar-iframe-btns {
  @apply text-[#424149] rounded-xl h-11 w-full flex gap-2 items-center justify-start rounded-[41px] hover:!bg-[#DCEDFF] hover:!text-[#007AFF] !outline-none
}

.sidebar-iframe-btns.active {
  @apply bg-[#DCEDFF] text-[#007AFF]
}